import React from "react"
import s from "./NewsletterHeader.module.scss"

const NewsletterHeader = ({ title }) => {
  return (
    <div
      className={s.BannerWrapper}
      style={{
        opacity: null,
        transitionDuration: "500ms",
      }}
    >
      <div className={s.RedBanner}>
        <h1 className={s.PageTitle}>{title}</h1>
      </div>
    </div>
  )
}

export default NewsletterHeader
