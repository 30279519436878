const strings = {
  de: {
    title: "Wo immer dein Durst daheim ist.",
    form_label: "Newsletter abonnieren",
    form_title: "Rivella erfrischt dein Postfach",
    form_desc:
      "Du möchtest stets up to date sein was unsere Marken betrifft und keine News mehr verpassen?<br/>Hier erfährst du als Erstes von Gewinnspielen, Promotionen und exklusiven Events aus dem Rivella Universum",
    email: "Email",
    first_name: "Vorname",
    plz: "PLZ",
    birthday: "Geburtsdatum",
    optional: "optional",
    interested_in: "Ich interessiere mich für",
    events: "Events",
    promotions: "Gewinnspiele und Promotionen",
    discount_codes: "Onlineshop Aktionen und Rabattcodes",
    new_products:
      "Neue Produkte und Produktvariationen aus dem Rivella Universum.",
    submit: "Newsletter Abonnieren",
  },
  fr: {
    title: "Wo immer dein Durst daheim ist.",
    form_label: "S'abonner à la newsletter",
    form_title: "Rivella rinfresca la tua casella di posta",
    form_desc:
      "Vuoi rimanere aggiornato con le ultime notizie sui nostri marchi e non perdere altre informazioni?<br/>Qui sarai il primo a scoprire concorsi, promozioni ed eventi esclusivi del mondo Rivella.",
    email: "e-mail",
    first_name: "prénom",
    plz: "code postal",
    birthday: "date de naissance",
    optional: "facultatif",
    interested_in: "Je suis intéressé par",
    events: "les événements",
    promotions: "les concours et les promotions",
    discount_codes:
      "les promotions et les codes de réduction des boutiques en ligne",
    new_products:
      "les nouveaux produits et les variations de produits de l'univers Rivella.",
    submit: "S'abonner à la newsletter",
  },
  it: {
    title: "Wo immer dein Durst daheim ist.",
    form_label: "Iscriviti alla newsletter",
    form_title: "Rivella rinfresca la tua casella di posta",
    form_desc:
      "Du möchtest stets up to date sein was unsere Marken betrifft und keine News mehr verpassen?<br/>Hier erfährst du als Erstes von Gewinnspielen, Promotionen und exklusiven Events aus dem Rivella Universum",
    email: "e-mail",
    first_name: "cognome",
    plz: "codice postale",
    birthday: "data di nascita",
    optional: "opzionale",
    interested_in: "Sono interessato a",
    events: "eventi",
    promotions: "concorsi e promozioni",
    discount_codes: "promozioni e codici di sconto di negozi online",
    new_products:
      "nuovi prodotti e varianti di prodotti dell'universo Rivella.",
    submit: "Iscriviti alla newsletter",
  },
}

export default strings
