import React from "react"
import strings from "./NewsletterBuilder.strings"
import { SITE_SCOPES } from "../../constants"
import { getCurrentLanguage } from "../../helper"
import { Layout } from "../index"
import NewsletterForm from "../../components/NewsletterForm/NewsletterForm"
import NewsletterHeader from "../../components/NewsletterHeader/NewsletterHeader"

export default function NewsletterBuilder({
  location,
  pageContext,
  siteScope = SITE_SCOPES.main,
}) {
  const currentLang = getCurrentLanguage(location)
  const data = strings[currentLang.combined]
  const { allEvents = [], allProducts = [], allPages = [] } = pageContext
  const languageOptions = {
    currentLang,
    options: [],
  }
  if (!data) return null
  return (
    <Layout
      pageName={data.title}
      headerIsTransparent={false}
      location={location}
      languageOptions={languageOptions}
      allProducts={allProducts}
      allEvents={allEvents}
      allPages={allPages}
      siteScope={siteScope}
    >
      {/*<MainPageHero*/}
      {/*    title_main={data.title}*/}
      {/*    title_main_color={'WhiteColor'}*/}
      {/*    background_img={'https://rivella.madebycolorelephant.com/wp-content/uploads/2020/06/Homepage_Top.jpg'}*/}
      {/*    banner_is_hidden={true}*/}
      {/*    location={location}*/}
      {/*/>*/}
      <NewsletterHeader title={data.title} />
      <NewsletterForm pageData={data} />
    </Layout>
  )
}
