import React, { useState } from "react"
import s from "./NewsletterForm.module.scss"
import { logos } from "./NewsletterForm.data"

const initialState = {
  name: "",
  email: "",
  zip: "",
  birthday: "",
  events: false,
  promotions: false,
  discount_codes: false,
  new_products: false,
}
const NewsletterForm = ({ pageData }) => {
  const [state, setState] = useState({ ...initialState })
  const [displayErrors, setDisplayErrors] = useState(false)
  const emailReg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // eslint-disable-next-line no-useless-escape
  const nameRegEx = /[^A-Za-zÀ-úü\s'\-]/g
  const update = ({ target: { name, value } }) => {
    switch (name) {
      case "zip":
        value = value.replace(/\D/g, "")
        break
      case "name":
        value = value.replace(nameRegEx, "")
        break
    }
    setState({ ...state, [name]: value })
  }
  const check = ({ target: { name, checked } }) =>
    setState({ ...state, [name]: checked })
  const submitForm = (e) => {
    e.preventDefault()
    setDisplayErrors(true)
    const { email, name } = state
    if (!name || !email || !emailReg.test(email)) return
    //todo: send API request, handle response
    alert("submitted!\n" + JSON.stringify(state))
    setDisplayErrors(false)
    setState({ ...initialState })
  }
  const CBX = ({ name, label }) => (
    <div className={`${s.Form__Field} ${s.Row}`}>
      <input
        type={"checkbox"}
        id={name}
        name={name}
        onChange={check}
        checked={state[name]}
        className={s.Form__Checkbox}
      />
      <label htmlFor={name} className={s.Form__CBX_Label}>
        {label}
      </label>
    </div>
  )
  return (
    <>
      <div className={s.MainWrapper}>
        <div className={s.Desc}>
          <h3 className={s.Desc__Label}>{pageData.form_label}</h3>
          <h2 className={s.Desc__Title}>{pageData.form_title}</h2>
          <p
            className={s.Desc__Intro}
            dangerouslySetInnerHTML={{ __html: pageData.form_desc }}
          />
        </div>
        <form className={s.Form} onSubmit={submitForm} noValidate>
          <div className={s.Form__Cont}>
            <div className={s.Form__Wrapper}>
              <div className={s.Form__Field}>
                <label htmlFor={"email"} className={s.Form__Label}>
                  {pageData.email}
                </label>
                <input
                  id={"email"}
                  className={s.Form__Input}
                  type={"email"}
                  name={"email"}
                  value={state.email}
                  onChange={update}
                />
                <span className={s.Form__Error}>
                  {displayErrors &&
                    (!state.email
                      ? "Required field"
                      : !emailReg.test(state.email)
                      ? "Invalid Email"
                      : "")}
                </span>
              </div>
              <div className={s.Form__Field}>
                <label htmlFor={"name"} className={s.Form__Label}>
                  {pageData.first_name}
                </label>
                <input
                  id={"name"}
                  className={s.Form__Input}
                  type={"text"}
                  name={"name"}
                  value={state.name}
                  onChange={update}
                />
                <span className={s.Form__Error}>
                  {displayErrors && (!state.name ? "Required field" : "")}
                </span>
              </div>
              <div className={s.Form__Field}>
                <label htmlFor={"zip"} className={s.Form__Label}>
                  {pageData.plz}&nbsp;
                  <span className={s.Secondary}> ({pageData.optional})</span>
                </label>
                <input
                  id={"zip"}
                  className={s.Form__Input}
                  type={"text"}
                  name={"zip"}
                  value={state.zip}
                  onChange={update}
                />
              </div>
              <div className={s.Form__Field}>
                <label htmlFor={"birthdate"} className={s.Form__Label}>
                  {pageData.birthday}&nbsp;
                  <span className={s.Secondary}> ({pageData.optional})</span>
                </label>
                <input
                  id={"birthdate"}
                  className={s.Form__Input}
                  placeholder={"DD/MM/YYYY"}
                  type={"date"}
                  name={"birthday"}
                  value={state.birthday}
                  onChange={update}
                />
              </div>
              <p className={s.Form__Interested}>
                {pageData.interested_in}&nbsp;({pageData.optional})
              </p>
              <CBX name={"events"} label={pageData.events} />
              <CBX name={"promotions"} label={pageData.promotions} />
              <CBX name={"discount_codes"} label={pageData.discount_codes} />
              <CBX name={"new_products"} label={pageData.new_products} />
            </div>
            <button type={"submit"} className={s.Form__Submit}>
              {pageData.submit}
            </button>
          </div>
        </form>
      </div>
      <div className={s.Separator} />
      <div className={s.Logos}>{logos}</div>
    </>
  )
}

export default NewsletterForm
